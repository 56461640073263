<template>
	<div>
		<!-- filters -->
		<v-row v-if="false" class="pb-3">
			<v-col md="3" sm="6" cols="12">
				<v-text-field
					:label="$t('search')"
					hide-details
					outlined
					dense
					prepend-inner-icon="mdi-magnify"
					class="rounded-medium"
				></v-text-field>
			</v-col>
			<v-spacer/>
			<v-col md="4" sm="6" cols="12">
				<v-row>
					<v-col sm="6" cols="12">
						<v-text-field
							:label="$t('from')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-calendar"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					<v-col sm="6" cols="12">
						<v-text-field
							:label="$t('to')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-calendar"
							class="rounded-medium"
						></v-text-field>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<!-- statistics -->
		<v-card v-if="false" :loading="loading" flat class="rounded-medium mb-2">
			<v-card-text class="py-6">
				<v-row>
					<!-- visitors -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.visitors')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">N/A</span>
								<v-chip pill small color="red lighten-5 error--text mt-1">
									<span class="me-1">5%</span>
									<v-icon small>mdi-trending-down</v-icon>
								</v-chip>
							</div>
							<p class="text-caption mb-0">
								5 {{$t('Dashboard.descriptions.visitors')}}
							</p>
						</div>
					</v-col>
					
					<!-- divider -->
					<v-col cols="auto" class="px-0" v-if="$vuetify.breakpoint.smAndUp">
						<v-divider vertical/>
					</v-col>
					
					<!-- users -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.users')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">{{statics.user_count || 0}}</span>
								<v-chip pill small color="green lighten-5 success--text mt-1">
									<span class="me-1">26%</span>
									<v-icon small>mdi-trending-up</v-icon>
								</v-chip>
							</div>
							<p class="text-caption mb-0">
								12 {{$t('Dashboard.descriptions.today')}}
							</p>
						</div>
					</v-col>
					
					<!-- divider -->
					<v-col cols="auto" class="px-0" v-if="$vuetify.breakpoint.lgAndUp">
						<v-divider vertical/>
					</v-col>
					
					<!-- disabled -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.disabled-products')}}
							</p>
							<div class="d-flex mt-2 mb-3">
								<span class="number-large main--text me-3">{{statics.disabled_product || 0}}</span>
							</div>
							<p class="text-caption mb-0">
								{{$t('Dashboard.descriptions.out-of-$-products', { number: statics.product_count || 0 })}}
							</p>
						</div>
					</v-col>

					<!-- divider -->
					<v-col cols="auto" class="px-0" v-if="$vuetify.breakpoint.smAndUp">
						<v-divider vertical/>
					</v-col>
					
					<!-- revenue -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.revenue')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">$0</span>
								<v-chip pill small color="green lighten-5 success--text mt-1">
									<span class="me-1">16%</span>
									<v-icon small>mdi-trending-up</v-icon>
								</v-chip>
							</div>
							<p class="text-caption mb-0">
								236 {{$t('Dashboard.descriptions.orders')}}
							</p>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- order & products -->
		<v-row class="mt-0 mb-0">
			<!-- order status -->
			<v-col v-if="showOrders" md="4" cols="12">
				<v-card :loading="loading" flat class="rounded-medium">
					<v-card-title class="text-capitalize font-weight-bold main--text ps-5 pb-0">
						{{$t('Dashboard.order-status.title')}}
					</v-card-title>
					<v-card-text class="px-3 py-0">
						<apex-chart
							type="bar"
							:labels="ordersLabels"
							:series="ordersData"
							:max-columns-count="5"
							:height="300"
							distributed-labels
							:colors="['#03a9f4', '#ff5722', '#1a237e', '#13a689', '#EF5350']"
						/>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- products views -->
			<v-col :md="showOrders ? 8 : null" cols="12">
				<v-card :min-height="300" :loading="loading" flat class="rounded-medium">
					<v-card-title class="text-capitalize font-weight-bold main--text ps-5 pb-0">
						{{$t('Dashboard.top-products-views', { count: productsLabels.length })}}
					</v-card-title>
					<v-card-text class="px-1 py-0">
						<apex-chart
							type="bar"
							show-tooltip
							trim-labels
							:max-columns-count="showOrders ? 13 : productsLabels.length"
							:labels="productsLabels"
							:series="productsViewsData"
							:height="300"
						/>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- statistics -->
			<v-col v-if="showStatics" cols="12">
				<v-card :min-height="300" :loading="loading" flat class="rounded-medium">
					<v-card-title class="ps-6 pb-0">
						<v-row
							dense
							align="center"
							class="justify-space-between ma-0"
							style="width: 100%"
						>
							<!-- title -->
							<v-col
								lg="2"
								cols="12"
								class="text-h6 text-capitalize font-weight-bold main--text mb-0"
							>
								{{$t('statistics.title')}}
							</v-col>

							<!-- charts types -->
							<v-col lg="8" sm="9" cols="12">
								<v-chip-group
									v-model="chartType"
									mandatory
									dense
									show-arrows
									active-class="primary"
								>
									<v-spacer/>
									<v-chip v-if="statistics.conversionRate" small>
										{{$t('statistics.average-order-value')}}
									</v-chip>
									<v-chip v-if="statistics.averageOrderValue" small>
										{{$t('statistics.cart-abandoned-rate')}}
									</v-chip>
									<v-chip v-if="statistics.cartAbandonedRate" small>
										{{$t('statistics.conversion-rate')}}
									</v-chip>
									<v-chip v-if="statistics.retentionRate" small>
										{{$t('statistics.retention-rate')}}
									</v-chip>
								</v-chip-group>
							</v-col>

							<!-- year -->
							<v-col lg="2" sm="3" cols="12" class="d-flex align-center">
								<v-btn
									icon
									@click="chartYear = moment(chartYear).subtract(1, 'years').format('YYYY')"
								>
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<v-text-field
									v-model="chartYear"
									dir="ltr"
									dense
									outlined
									hide-details
									readonly
									class="year-input rounded-medium"
								></v-text-field>
								<v-btn
									icon
									:disabled="chartYear === moment().format('YYYY')"
									@click="chartYear = moment(chartYear).add(1, 'years').format('YYYY')"
								>
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text class="px-3 py-0">
						<apex-chart
							type="line"
							:series="chartSeries"
							:max-columns-count="5"
							:height="350"
							:year="chartYear"
							datetime
							distributed-labels
						/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- resent added && popular products -->
		<v-row class="mt-0">
			<!-- resent added -->
			<v-col md="5" cols="12">
				<v-card flat class="rounded-medium">
					<v-card-title class="font-weight-bold main--text pb-3">
						{{$t('Dashboard.resent-added-products')}}
					</v-card-title>
					<v-card-text class="pb-6">
						<v-data-table
							:headers="recentHeaders"
							:items="loading ? [] : statics.recent_add_products.slice(0, 10)"
							:loading="loading"
							:items-per-page="15"
							disable-sort
							hide-default-footer
						>
							<!-- no -->
							<template v-slot:item.no="{ index }">
								{{index+1}}
							</template>
							
							<!-- image -->
							<template v-slot:item.image="{ item }">
								<v-img
									:src="item.image.url"
									:height="45"
									:width="45"
									lazy-src="@/assets/placeholder-image.png"
									class="rounded-medium mx-auto"
								/>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- popular products -->
			<v-col md="7" cols="12">
				<v-card flat class="rounded-medium">
					<v-card-title class="font-weight-bold main--text pb-3">
						{{$t('Dashboard.popular-products')}}
					</v-card-title>
					<v-card-text class="pb-6">
						<v-data-table
							:headers="popularHeaders"
							:items="loading ? [] : statics.popular_product.slice(0, 10)"
							:loading="loading"
							:items-per-page="15"
							disable-sort
							hide-default-footer
						>
							<!-- no -->
							<template v-slot:item.no="{ index }">
								{{index+1}}
							</template>

							<!-- image -->
							<template v-slot:item.image="{ item }">
								<v-img
									:src="item.image.url"
									:height="45"
									:width="45"
									lazy-src="@/assets/placeholder-image.png"
									class="rounded-medium mx-auto"
								/>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { orders } from '@/configs/routes.config';
import { statistics } from '@/configs/global.config';
import moment from 'moment';

export default {
	name: 'Dashboard',

	data: () => ({
		// helpers
		loading: false,
		moment,
		chartYear: moment().format('YYYY'),

		// configs
		showOrders: orders.show,
		showStatics: Object.keys(statistics).some(key => statistics[key]),
		statistics,

		// data
		ordersData: [],

		productsLabels: [],
		productsViewsData: [{ data: [] }],
		
		// statistics
		chartType: 0,
	}),

	computed: {
		...mapState({
			statics: state => state.dashboard.statics
		}),

		ordersLabels() {
			return [
				this.$t('orderStates.approved'),
				this.$t('orderStates.in-progress'),
				this.$t('orderStates.shipping'),
				this.$t('orderStates.delivered'),
				this.$t('orderStates.canceled'),
			]
		},

		chartSeries() {
			const series = [];
			if (this.chartType === 0) {
				series.push({
					name: this.$t('statistics.average-order-value'),
					data: [
						['2023-02-01', 120],
						['2023-09-01', 400],
						['2023-11-01', 300],
						['2023-12-04', 120],
						['2024-01-08', 20],
						['2024-02-01', 14],
					]
				})
			} else if (this.chartType === 1) {
				series.push({
					name: this.$t('statistics.cart-abandoned-rate'),
					data: [
						['2023-08-01', 20],
						['2023-10-01', 17],
						['2023-11-01', 81],
						['2023-12-04', 71],
						['2024-02-02', 9],
					]
				})
			} else if (this.chartType === 2) {
				series.push({
					name: this.$t('statistics.conversion-rate'),
					data: [
						['2023-04-01', 30],
						['2023-06-01', 64],
						['2023-08-01', 24],
						['2023-11-04', 14],
						['2024-02-08', 3],
					]
				})
			} else if (this.chartType === 3) {
				series.push({
					name: this.$t('statistics.retention-rate'),
					data: [
						['2023-02-01', 21],
						['2023-04-03', 59],
						['2023-06-01', 85],
						['2023-08-04', 34],
						['2024-02-02', 14],
					]
				})
			}
			return series;
		},

		recentHeaders() {
			const headers = [
				{ text: this.$t('headers.no'), value: 'no', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center' },
				{ text: this.$t('headers.visits'), value: 'view', align: 'center' },
			]
			if (this.showOrders) {
				headers.splice(headers.length, 0, { text: this.$t('headers.revenue'), value: 'revenue', align: 'center' },);
			}
			return headers
		},
		
		popularHeaders() {
			const headers = [
				{ text: this.$t('headers.no'), value: 'no', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center' },
				{ text: this.$t('headers.visits'), value: 'view', align: 'center' },
			]
			if (this.showOrders) {
				headers.splice(
					headers.length,
					0,
					{ text: this.$t('headers.revenue'), value: 'revenue', align: 'center' },
					{ text: this.$t('headers.orders'), value: 'order', align: 'center' }
				);
			}
			return headers
		},
	},

	created() {
		this.loading = true;
		this.$store.dispatch('dashboard/fetchAll')
			.then((data) => {
				const labels = [];
				const staticsData = [];
				data.products_view.forEach(product => {
					labels.push(product.name.length > 20 ? product.name.slice(0, 20) + '...' : product.name);
					// staticsData.push(product.view + Math.round(Math.random() * 120))
					staticsData.push(product.view)
				});
				
				this.productsLabels = labels;
				this.productsViewsData = [{
					name: this.$t('Dashboard.views'),
					data: staticsData.sort((a, b) => b - a)
				}];

				this.ordersData = [{ name: this.$t('Dashboard.count'), data: [10, 25, 15, 51, 20] }];

				this.loading = false;
			})
	}
};
</script>

<style lang="scss" scoped>
.statistic-card {
	width: 70%;
	margin: 0 auto;
}
</style>
