import { filters, keywords, sets, categories, users, products } from '@/configs/translates.config';

export default {
	search: {
		en: 'search',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	from: {
		en: 'from',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	to: {
		en: 'to',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	
	add: {
		en: 'Add',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	edit: {
		en: 'Edit',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	delete: {
		en: 'delete',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	restore: {
		en: 'restore',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	save: {
		en: 'Save',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	change: {
		en: 'Change',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	cancel: {
		en: 'Cancel',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	close: {
		en: 'Close',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	back: {
		en: 'Back',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	select: {
		en: 'Select',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	confirm: {
		en: 'Confirm',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	activate: {
		en: 'activate',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	deactivate: {
		en: 'deactivate',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	upload: {
		en: 'Upload',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'are-you-sure-you-want-to': {
		en: 'Are you sure you want to',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	'changing-order': {
		en: 'Changing order',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'upload-images': {
		en: 'Upload images',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'SEO-fields': {
		en: 'SEO fields',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'drag-text': {
		en: 'Drag and drop image here',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'upload-more-image': {
		en: 'Upload more images',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'supported-text': {
		en: 'supported files: jpg, jpeg, png.',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	'please-wait-uploading-the-images': {
		en: 'Please wait uploading the images',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	'$-is-updated-successfully': {
		en: '{name} is updated successfully',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'$-is-created-successfully': {
		en: '{name} is created successfully',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	headers: {
		en: {
			no: 'No',
			image: 'Image',
			video: 'Video',
			name: 'Name',
			sets: sets.en.plural.charAt(0).toUpperCase() + sets.en.plural.slice(1),
			visits: 'Visits',
			orders: 'Orders',
			products: 'products',
			revenue: 'Revenue',
			summary: 'Summary',
			order: 'Order',
			actions: 'Actions',
			category: 'Category',
			manufacturer: 'Manufacturer',
			quantity: 'Quantity',
			price: 'Price',
			title: 'Title',
			descriptions: 'descriptions',
			user: 'User',
			'created-at': 'Created at',
			total: 'Total',
			amount: 'Amount',
			'trans-code': 'Trans code',
			paid: 'Paid',
			'payment-type': 'Payment type',
			'current-status': 'Current status',
			address: 'Address',
			email: 'Email',
			phone: 'Phone',
			mobile: 'Mobile',
			username: 'Username',
			link: 'Link',
			description: 'description',
			fullname: 'Fullname',
			country: 'country',
			active: 'Active',
			date: 'Date',
			code: 'code',
			views: 'Views',
			featured: 'Featured',
			'in-home': 'In home',
			'has-plan': 'Has plan',
			'coupon-code': 'Coupon code',
			'max-beneficiaries-number': 'Max beneficiaries number',
			'from-date': 'From date',
			'to-date': 'To date',
			discount: 'Discount',
			'main-branch': 'Main branch',
			'country-code': 'Country code',
			'money-code': 'Money code',
			'opening-hours': 'Opening hours',
			duration: 'Duration',
			'offer-start-date': ' Offer start date',
			'offer-end-date': ' Offer end date',
			'offer-price': ' Offer price',
			text: 'Text',
			type: 'Type',
			options: 'Options',
			'points-count': 'Points count',
			state: 'state',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	inputs: {
		en: {
			name: 'name',
			set: sets.en.singular,
			'parent-category': `parent ${categories.en.singular}`,
			'product-name': 'product name',
			'product-category': 'product category',
			summary: 'summary',
			code: 'code',
			order: 'order',
			description: 'description',
			category: 'category',
			keywords: keywords.en.plural,
			price: 'price',
			'subscription-price': 'subscription price',
			filter: filters.en.plural,
			file: 'file',
			link: 'link',
			attributes: 'attributes',
			'is-featured': 'is featured',
			'is-main-branch': 'is main branch',
			'is-in-home-page': 'is in home page',
			'is-quantitative': 'is quantitative',
			'related-products': `related ${products.en.plural}`,
			'suggest-related-from-categories': `suggest related from ${categories.en.plural}`,
			'date-of-publication': 'Date of publication',
			date: 'date',
			title: 'title',
			text: 'text',
			type: 'type',
			
			'plan-name': 'plan name',
			duration: 'duration (in months)',
			offer: 'offer',
			'offer-start-date': 'offer start date',
			'offer-end-date': 'offer end date',
			'offer-price': 'offer price',

			user: 'customer',
			'max-beneficiaries-number': 'max beneficiaries number',
			holidays: 'holidays',
			'apply-on-up-selling': 'apply on up selling',
			'coupon-products': 'coupon products',
			'coupon-code': 'coupon code',
			'from-date': 'from date',
			'to-date': 'to date',
			discount: 'discount',
			percent: 'percent',
			'fixed-value': 'fixed value',
			'offer-products': 'offer products',

			username: 'username',
			password: 'Password',
			'confirm-password': 'confirm password',
			'seo-description': 'SEO description',
			'seo-keywords': 'SEO keywords',
			'alt-text': 'Alt text',
			
			'opening-hours': 'opening hours',
			'country-code': 'country code',
			'money-code': 'money code',
			location: 'google maps location URL',
			email: 'email',
			address: 'address',
			phone: 'phone',
			mobile: 'mobile',
			whatsapp: 'whatsapp',
			facebook: 'facebook page URL',
			telegram: 'telegram',
			'telegram-channel': 'telegram channel',
			instagram: 'instagram page URL',
			twitter: 'twitter page URL',
			youtube: 'youtube page URL',
			linkedIn: 'linkedIn page URL',
			pinterest: 'pinterest page URL',
			video: 'video',

			'max-orders-per-day': 'max orders per day',
			'loyalty-point-price': 'loyalty point price',
			'expiry-duration': 'expiry duration',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	messages: {
		en: {
			'deleted-successfully': 'Delete operation completed successfully',
			'restored-successfully': 'Restore operation completed successfully',
			'there-is-uploaded-file': 'There\'s an uploaded file',
			'item-order-is-updated': 'Item order is updated',
			'image-uploaded': 'Image uploaded successfully',
			'image-deleted': 'Image deleted successfully',
			'session-is-end': 'Your session is END, please Login again',
			uploading: 'Are you sure you want to reload the page? uploading operation will be cancelled',
			'check-your-connection': 'please, check your internet connection',
			copied: 'Copied to the clipboard',
		},
		ar: {
			'deleted-successfully': 'عملية  الحذف تمت بنجاح',
			'restored-successfully': 'عملية  الاسترجاع تمت بنجاح',
			'there-is-uploaded-file': 'هناك ملف مرفوع',
			'item-order-is-updated': 'تم تحديث ترتيب العنصر',
			'image-uploaded': 'تم رفع الصورة بنجاح',
			'image-deleted': 'تم حذف الصورة بنجاح',
			'session-is-end': 'جلستك انتهت، الرجاء تسجيل الدخول مرة أخرى',
			uploading: 'هل أنت متأكد من إعادة تحميل الصحفة؟ هذا سوف يلغي عملية الرفع',
			'check-your-connection': 'رجاءً تحقق من اتصالك بالإنترنت',
			copied: 'تم النسخ إلى الحافظة',
		}
	},

	permissionTypes: {
		en: {
			read: 'Read',
			write: 'Read & Write',
			'no-access': 'No access'
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	orderStates: {
		en: {
			approved: 'approved',
			'in-progress': 'in progress',
			shipping: 'shipping',
			delivered: 'delivered',
			canceled: 'canceled',
			paid: 'paid',
			unpaid: 'unpaid',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	statistics: {
		en: {
			title: 'statistics',
			'average-order-value': 'Average order value (AOV)',
			'cart-abandoned-rate': 'Cart abandoned rate',
			'conversion-rate': 'Conversion rate',
			'retention-rate': 'Retention rate',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	'week-days': {
		en: {
			saturday: 'Saturday',
			sunday: 'Sunday',
			monday: 'Monday',
			tuesday: 'Tuesday',
			wednesday: 'Wednesday',
			thursday: 'Thursday',
			friday: 'Friday',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	upSellingEntryTypes: {
		en: {
			'yes-no': 'Yes/No',
			count: 'Count',
			'multi-choice': 'Multi-choice',
			multiple: 'Multiple',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	'file-manager': {
		en: {
			'the-item': 'the Item?',
			'alt-text': 'Alternative description',
			tabs: {
				images: 'images',
				videos: 'videos',
				files: 'files',
				upload: 'upload',
			},
			'no-images': 'No images.',
			'no-videos': 'No videos.',
			'no-files': 'No files.',
			'upload-type': {
				image: 'image',
				video: 'video',
				file: 'file',
			},
			loading: 'Loading ...'
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	tooltip: {
		en: {
			'preview-on-website': 'preview on website',
			'preview-order-details': 'preview order details',
			'preview-user-info': `preview ${users.en.singular} info`,
			'plan-users': 'plan\'s users',
			edit: 'edit',
			'change-order': 'change order',
			delete: 'delete',
			restore: 'restore',
			'active-state': 'active state',
			'drag-to-change-order': 'drag to change order',
			upload: 'upload',
			'change-size': 'change size',
			refresh: 'refresh',
			'copy-url': 'copy url',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	components: {
		en: {
			'file-manager': 'File manger',
			filesFilters: {
				small: 'S',
				medium: 'M',
				large: 'L',
				original: 'Original',
			},
			fileTypes: {
				images: 'Images',
			},
			upload: 'Upload',
			'size-to-use': 'Size image to use',
			size: 'Size',
			'headers-manager': {
				title: 'columns manager',
				placeholder: 'column name'
			}
		},
		ar: {
			'file-manager': 'مدير الملفات',
			filesFilters: {
				small: 'صغير',
				medium: 'متوسطة',
				large: 'كبير',
				origanl: 'الأصلي',
			},
			fileTypes: {
				image: 'صور',
			},
			upload: 'رفع',
			'size-to-use': 'استخدم للصورة القياس',
			size: 'الحجم',
			'headers-manager': {}
		},
		es: {
			'file-manager': '',
			filesFilters: {
				small: '',
				medium: '',
				large: '',
				origanl: '',
			},
			fileTypes: {
				image: '',
			},
			upload: 'رفع',
			'size-to-use': '',
			size: ''
		},
		fr: {
			'file-manager': '',
			filesFilters: {
				small: '',
				medium: '',
				large: '',
				origanl: '',
			},
			fileTypes: {
				image: '',
			},
			upload: '',
			'size-to-use': '',
			size: ''
		},
		fa: {
			'file-manager': '',
			filesFilters: {
				small: '',
				medium: '',
				large: '',
				origanl: '',
			},
			fileTypes: {
				image: '',
			},
			upload: '',
			'size-to-use': '',
			size: ''
		},
	}
}